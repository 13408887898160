import React from 'react';
import './MasonryGallery.css';

function MasonryGallery({ images }) {
  return (
    <div className="masonry">
      {images.map((image, index) => (
        <div key={index} className="masonry-item">
          <img
            src={image.src}
            alt={image.alt}
            className="masonry-img"
            loading="lazy" // Lazy loading for better performance
          />
        </div>
      ))}
    </div>
  );
}

export default MasonryGallery;

import React, { useEffect, useState } from 'react';
import apiClient from '../../api/apiClient';
import MembershipSelect from '../../components/MembershipSelect/MembershipSelect';
import Password from '../../components/Password/Password';
import SubmitButton from '../../components/Buttons/Submit/SubmitButton';

const Register = () => {
    const [membershipTypes, setMembershipTypes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        telephone: '',
        membershipType: '',
        password: ''
    });

    useEffect(() => {
        apiClient.get(`${process.env.REACT_APP_BACKEND_URL}membership-types`)
            .then(response => {
                setMembershipTypes(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error al obtener los datos:', error);
                setError('No se pudieron cargar los tipos de membresía. Intenta nuevamente.');
                setLoading(false);
            });
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const validateForm = () => {
        // Add your validation logic here
        return true; // Return false if validation fails
    };

    const handleRegister = (e) => {
        e.preventDefault();
        if (validateForm()) {
            // Lógica para manejar el registro
            console.log('Datos del formulario:', formData);
            // Aquí puedes hacer una llamada a la API para registrar al usuario
        } else {
            console.error('Validation failed');
        }
    };

    return (
        <div className="register-container">
            <form className="register-form" onSubmit={handleRegister}>
                <h2>Nuevo miembro</h2>

                <div className="form-group">
                    <label htmlFor="nombre">Nombre</label>
                    <input
                        type="text"
                        name="firstName"
                        placeholder="Nombre"
                        value={formData.firstName}
                        onChange={handleInputChange}
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="apellido">Apellido</label>
                    <input
                        type="text"
                        name="lastName"
                        placeholder="Apellido"
                        value={formData.lastName}
                        onChange={handleInputChange}
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="celular">Celular</label>
                    <input
                        type="tel"
                        name="telephone"
                        placeholder="Celular"
                        value={formData.telephone}
                        onChange={handleInputChange}
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="membershipType">Membresía</label>
                    {loading ? (
                        <p>Cargando tipos de membresía...</p>
                    ) : error ? (
                        <p className="error">{error}</p>
                    ) : (
                        <MembershipSelect
                            membershipTypes={membershipTypes}
                            value={formData.membershipType}
                            onChange={(e) => setFormData({ ...formData, membershipType: e.target.value })}
                        />

                    )}
                </div>

                <div className="form-group">
                    <label htmlFor="password">Contraseña</label>
                    <Password
                        value={formData.password}
                        onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                        required
                    />
                </div>

                <SubmitButton text="Hacerme socio" />
            </form>
        </div>
    );
};

export default Register;

// src/components/Footer.js
import React from 'react';
import './AboutUsHome.css';

function AboutUsHome() {
    return (
        <div className='container'>
            <h2>Sobre el rancho</h2>
            <p>
                Rancho Sport es un lugar único y dedicado a la pasión por el deporte.
                Nuestro rancho ofrece instalaciones de primera calidad para los amantes del pádel.
                Contamos con dos canchas de pádel de última generación, diseñadas para brindar una experiencia de juego óptima, 
                tanto para principiantes como para jugadores experimentados.
            </p>
            <p>
                En Rancho Sport, nos enorgullece ofrecer un ambiente acogedor y familiar, donde el deporte se vive con entusiasmo y camaradería. 
                Nuestro equipo está comprometido con la excelencia en el servicio y la satisfacción de nuestros visitantes, asegurando que cada visita 
                sea memorable.
            </p>
        </div>
    );
}

export default AboutUsHome; // Exportación por defecto
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './ReservationButton.module.css';

function ReservationButton({ text, to }) {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(to);
    };

    return (
        <button className={styles.reservationButton} onClick={handleClick}>
            {text}
        </button>
    );
}

export default ReservationButton;

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import NotFound from './components/NotFound/NotFound';
import Home from './pages/Home/Home';
import About from './pages/About';
import ConfirmReservation from './pages/ConfirmReservation/ConfirmReservation';
import Register from './pages/Register/Register';
import { AuthProvider, useAuth } from './context/AuthProvider';
import PageLogin from './pages/Login/Login';
import { history } from './utils/history';
import { setupInterceptors } from './api/apiClient';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

function App() {
  const { logout } = useAuth();

  useEffect(() => {
    setupInterceptors(logout);
  }, [logout]);

  return (

    <Router history={history}>
      <Header />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <div className='appContainer'>
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/reserva" element={<Reservation />} /> */}
          <Route path="/confirmar-reserva" element={<ConfirmReservation />} />
          <Route path="/about" element={<About />} />
          <Route path="/registro" element={<Register />} />
          <Route path="/login" element={<PageLogin />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default function RootApp() {
  return (
    <AuthProvider>
      <App />
    </AuthProvider>
  );
}


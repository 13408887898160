import React from 'react';
import styles from './Home.module.css'; // Importa el CSS Module específico de la página
import ReservationButton from '../../components/ReservationButton/ReservationButton';
import MasonryGallery from '../../components/MasonryGallery/MasonryGallery';
import AboutUsHome from '../../components/AboutUsHome/AboutUsHome';


const imageList = [
    { src: process.env.REACT_APP_FRONTEND_URL + 'home/1.jpg', alt: 'Image 1' },
    { src: process.env.REACT_APP_FRONTEND_URL + 'home/2.jpg', alt: 'Image 2' },
    { src: process.env.REACT_APP_FRONTEND_URL + 'home/3.jpg', alt: 'Image 3' },
    { src: 'https://lapala.mx/wp-content/uploads/2023/06/padel-6322450_1280.jpg', alt: 'Image 4' },
    { src: 'https://www.latercera.com/resizer/Y2RtovCCmAVUEguauny0dmtQ7kA=/arc-anglerfish-arc2-prod-copesa/public/V52OU5VV4BB4ZHVBG4Z2UDMLLQ.jpg', alt: 'Image 5' },
    { src: 'https://www.bnlitalymajorpremierpadel.com/media_contents/news/146413/main/preview_20220522_Campo_padel_MONDO_superficie.jpg', alt: 'Image 6' },
    { src: 'https://www.bnlitalymajorpremierpadel.com/media_contents/news/146496/main/preview_20220526_Negozio_racchette_padel.jpg', alt: 'Image 7' },
    { src: 'https://i0.wp.com/thepadelpaper.com/wp-content/uploads/2023/04/317277112_579564064173031_1164823691064652723_n.jpg?resize=640%2C427&ssl=1', alt: 'Image 8' },
];

function Home() {
    return (
        <>
            <div className={styles.homeContainer}>
                <div className={styles.blurredOverlay}>
                    <h1 className={styles.title}>Rancho Sport</h1>
                    <p className={styles.description}>
                        ¡Reserva tu cancha y viví la emoción del pádel en Rancho Sport!
                    </p>
                    <p className={styles.description}>
                        No pierdas la oportunidad de disfrutar de nuestras modernas instalaciones y ambiente amigable.
                    </p>
                    <ReservationButton text="Hazte socio" to="reserva" />
                </div>
            </div>
            <MasonryGallery images={imageList} />
            <AboutUsHome />
        </>
    );
}

export default Home;
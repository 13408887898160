import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Header.css';
import logo from '../../assets/images/logo-blanco-transparente.png';
import { ReactComponent as MenuIcon } from '../../assets/icons/menu.svg'; // Import SVG icon
import { AuthContext } from '../../context/AuthProvider';


function Header() {
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  // Estado para controlar la visibilidad del sidebar
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { isLoggedIn, logout } = useContext(AuthContext);
  const toggleSidebar = () => {
    setIsSidebarOpen(prevState => !prevState); // Usa prevState para garantizar que el cambio de estado sea siempre el correcto
  };

  return (
    <header className="header">
      <img src={logo} alt="Logo" className="logo" />
      <nav className="nav">
        <Link to="/" className="navLink">Inicio</Link>
        {/* <Link to="/reserva" className="navLink">Reservar</Link> */}
        {!isLoggedIn && <Link to="/registro" className="navLink">Asociate</Link>}
        {!isLoggedIn && <Link to="/login" className="navLink">Iniciar sesión</Link>}
        {isLoggedIn && <Link to="/perfil" className="navLink">Perfil</Link>}
        {isLoggedIn && <Link to="/" className="navLink" onClick={handleLogout}>Cerrar sesión</Link>}

        {/* Add more navigation links here */}
      </nav>
      <MenuIcon className="menuIcon" onClick={toggleSidebar} />
      <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
        <div className="sidebarLogoContainer">
          <img src={logo} alt="Logo" className="sidebarLogo" />
        </div>
        <Link to="/" className="navLink" onClick={toggleSidebar}>Inicio</Link>
        {/* <Link to="/reserva" className="navLink" onClick={toggleSidebar}>Reservar</Link> */}
        {!isLoggedIn && <Link to="/registro" className="navLink" onClick={toggleSidebar}>Asociate</Link>}
        {!isLoggedIn && <Link to="/login" className="navLink" onClick={toggleSidebar}>Iniciar sesión</Link>}
        {isLoggedIn && <Link to="/perfil" className="navLink" onClick={toggleSidebar}>Perfil</Link>}
        {isLoggedIn && <Link to="/" className="navLink" onClick={handleLogout}>Cerrar sesión</Link>}
        {/* Add more sidebar links here */}
      </div>
      <div className={`backdrop ${isSidebarOpen ? 'open' : ''}`} onClick={toggleSidebar}></div>
    </header>
  );
}

export default Header;

import React, { createContext, useContext, useState } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [authToken, setAuthToken] = useState(localStorage.getItem('token'));
    const [isLoggedIn, setIsLoggedIn] = useState(!!authToken);

    const login = (token) => {
        setAuthToken(token);
        setIsLoggedIn(true);
        localStorage.setItem('token', token);
    };

    const logout = () => {
        setAuthToken(null);
        setIsLoggedIn(false);
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        localStorage.removeItem('email');
    };

    return (
        <AuthContext.Provider value={{ authToken, isLoggedIn, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};

import { format, parseISO } from 'date-fns';

const timeZone = process.env.REACT_APP_TIME_ZONE || 'UTC'; // Default a UTC si no se proporciona una zona horaria

export function formatTime(timeString) {
    const date = timeString.includes('T') ? parseISO(timeString) : new Date(`1970-01-01T${timeString}:00`);
    return format(date, 'HH:mm'); // Formato de 24 horas
}

export function formatDate(dateString) {
    // Asegúrate de que dateString esté en el formato correcto (yyyy-MM-dd)
    const date = parseISO(dateString);
    return format(date, 'dd-MM-yyyy'); // Formato de fecha dd-MM-yyyy
}
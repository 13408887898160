import React, { useState } from 'react';
import { formatPrice } from '../../utils/pricesUtils';

const MembershipSelect = ({ membershipTypes, value, onChange }) => {
    // Render only if membershipTypes has elements
    if (membershipTypes && membershipTypes.length > 0) {
        return (
            <select
                name="membershipType"
                id="membershipType"
                value={value}
                onChange={onChange}
                required
            >
                <option value="" disabled>Selecc. de membresía</option>
                {membershipTypes.map(membershipType => (
                    <option key={membershipType.id} value={membershipType.id}>
                        {membershipType.name} - ${formatPrice(membershipType.price)}
                    </option>
                ))}
            </select>
        );
    }

    // If no elements in membershipTypes, render nothing
    return null;
};

export default MembershipSelect;

import React, { useState, useEffect } from "react";
import './ConfirmReservation.css'; // Asegúrate de que el archivo CSS esté correctamente importado
import { formatTime, formatDate } from '../../utils/dateUtils';
import apiClient from '../../api/apiClient';
import { useNavigate } from 'react-router-dom';


function ConfirmReservation() {
    const [userName, setUserName] = useState('');
    const [resourceName, setResourceName] = useState('');
    const [reservationDate, setReservationDate] = useState('');
    const [reservationHour, setReservationHour] = useState('');
    const [slot, setSlot] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        // Recupera los valores de localStorage
        const storedUserName = JSON.parse(localStorage.getItem('username'));
        const storedReservationDate = JSON.parse(localStorage.getItem('pendingReservation'));

        if(storedReservationDate === null) navigate('/reserva');

        // Actualiza el estado con los valores recuperados
        if (storedUserName) {
            setUserName(storedUserName);
        }
        if (storedReservationDate) {
            setResourceName(storedReservationDate.resourceName);
            setReservationDate(formatDate(storedReservationDate.date));
            setReservationHour(formatTime(storedReservationDate.slot.from));
            setSlot(storedReservationDate.slot);
        }
    }, [navigate]);

    const handleConfirmReservation = () => {
        const reservation = JSON.parse(localStorage.getItem('pendingReservation'));


        const data = {
            resourceId: reservation.resourceId,
            slot: reservation.slot,
        }

        apiClient.post(process.env.REACT_APP_BACKEND_URL + 'reservations', data)
            .then(response => {
                const responseData = response.data;
                if (responseData.status === 'confirmed') {
                    localStorage.removeItem('pendingReservation');
                    navigate('/');
                }
            })
            .catch(error => {
                console.error('Error al crear la reserva:', error);
            });
    };

    return (
        <div className="confirmReservationDataContainer">
            <h3>Hola, {userName}</h3>
            <p>¿Deseas confirmar tu reserva?</p>
            <p>Cancha: <span>{resourceName}</span></p>
            <p>Día: <span>{reservationDate}</span></p>
            <p>Hora: <span>{reservationHour}</span></p>
            <div className="confirmReservationDataButtonsContainer">
                <button className="btnConfirm" onClick={() => handleConfirmReservation(userName, resourceName, slot)}>Confirmar</button>
                <button className="btnCancel" >Cancelar</button>
            </div>
        </div>
    );
}

export default ConfirmReservation;

import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const apiClient = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL, // Asegúrate de configurar esta variable de entorno
    timeout: 10000, // Puedes ajustar el tiempo de espera según sea necesario
    headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
});

export const setupInterceptors = (logoutCallback) => {
    apiClient.interceptors.response.use(
        response => response,
        error => {
            const { response } = error;
            if (response && response.status === 401) {
                logoutCallback(); // Llama a la función logout para manejar la expiración del token
                const navigate = useNavigate();
                navigate('/login');
            }
            return Promise.reject(error);
        }
    );
};

export default apiClient;
